import './App.css';

import { useState, useEffect, useRef, useCallback } from 'react';
import { TextField, CircularProgress, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import ReactJson from 'react-json-view'

import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';

function getData(ip) {
    return []
}

const initialCenter = { lat: -15.77972, lng: -47.92972 };

function App() {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [map, setMap] = useState(null)
    const [center, setCenter] = useState({})
    const [value, setValue] = useState('');
    const [zoom, setZoom] = useState(4)

    const mapRef = useRef(null);

    const onLoad = useCallback(function callback(map) {
        map.setZoom(4);
        setMap();
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    function handleClick(ip) {
        setLoading(true);
        getData(ip).then(data => {
            if (data) {
                setData(data);
                setCenter({lat: Number(data.latitude), lng: Number(data.longitude)});
                mapRef.current.state.map.setCenter({lat: Number(data.latitude), lng: Number(data.longitude)});
                mapRef.current.state.map.setZoom(12);
                setZoom(12);
                setLoading(false);
            } else {
                setData({Resultado: "Não Encontrado"});
                setCenter(initialCenter);
                mapRef.current.state.map.setCenter(initialCenter);
                mapRef.current.state.map.setZoom(4);
                setZoom(4);
                setLoading(false);
            }
        })
    }

    function onChange(event) {
        if ((event.target.value.split('.').join("").replace('.','').split('').every((value) => !isNaN(value))) && (event.target.value.length < 16)) {
            setValue(event.target.value)
        } else {
            setValue(value)
        }
    }

    return (
        <div>
        <div className="closed-container">
            <div className="closed">
            Sessão Encerrada
            </div>        
        </div>
        <div className="App">
            <header className="App-header">
                Análise de IP Anti-Fraude
            </header>
            <div className="App-body">
                <div className="body-container">
                    
                    <div className="mega-container">
                    
                        <div className="main-container">
                            <TextField 
                                value={value}
                                onKeyDown={(e) => {
                                    if(e.keyCode === 13){
                                        handleClick(value)
                                    }
                                }}
                                disabled
                                onChange={onChange}
                                className="ip-input"
                                id="outlined-error" 
                                placeholder="Digite o IP" 
                                variant="standard" 
                                InputProps={{
                                    endAdornment: 
                                        <IconButton 
                                            onClick={() => handleClick(value)}
                                            type="submit" 
                                            aria-label="search"
                                            disabled
                                            >
                                            <Search />
                                        </IconButton>
                                }}
                            />
                            <div className="json-output-container">
                                {loading ? (<div className="progress-container">
                                    <CircularProgress />
                                    Carregando Informações
                                </div>)
                                : <ReactJson        
                                    src={data} 
                                    name={false}
                                    // theme="monokai" 
                                    enableClipboard={false}
                                    displayObjectSize={false}
                                    collapsed={1}
                                    displayDataTypes={false}
                                />
                                }
                            </div>
                        </div>
                        <div className="map-container">
                        <LoadScript
                              googleMapsApiKey=""
                            >
                              <GoogleMap
                                ref={mapRef}
                                mapContainerClassName ={'map-inner-container'}
                                center={initialCenter}
                                zoom={zoom}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                              >
                                <>
                                    {data && data.latitude && Object.keys(center).length && (
                                        <Circle 
                                            center={center}
                                            radius={2000}
                                        />
                                    )}
                                </>
                              </GoogleMap>
                        </LoadScript>
                        </div>
                    </div>
                </div>
            </div>
            <div className="App-footer">
                Todos os Direitos Reservados @ Nexus Brasil
            </div>
        </div>
        </div>
    );
}

export default App;
